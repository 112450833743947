<template>
  <div>
    <spinner-loader :loading="loading" />
    <div class="text-right mb-1">
      <back />
    </div>
    <common-table
      v-bind="{
        fetchData:getBookingTickets,
        tableColumns,
        pagination,
      }"
      empty-text="No tickets found"
    />

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

  </div>
</template>

<script>
import Pagination from '@/common/components/common/Table/Pagination.vue'
import CommonTable from '@/common/components/common/Table/CommonTable.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import paginationData from '@/common/compositions/common/paginationData'
import Back from '@/common/components/common/FormInputs/Back.vue'

export default {
  name: 'TicketsList',
  components: {
    Pagination,
    CommonTable,
    SpinnerLoader,
    Back,
  },
  data() {
    return {
      tableColumns: [
        { key: 'id' },
        { key: 'userName' },
        { key: 'contactNumber' },
        { key: 'sessionName' },
        { key: 'eventName' },
        { key: 'startDate' },
        { key: 'endDate' },
        { key: 'status' },
        { key: 'language' },
      ],
      loading: true,
    }
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    getBookingTickets() {
      return this.$activities.get(`internalops/bookings/${this.$route.params.id}/tickets`).then(res => {
        const tickets = res.data.data
        return tickets || []
      }).catch(() => []).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
</style>
