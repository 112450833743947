<template>
  <b-card>
    <b-table
      id="table"
      ref="table"
      primary-key="id"
      :no-provider-sorting="true"
      :items="fetchData"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      show-empty
      responsive
      :empty-text="$attrs['empty-text']"
    >
      <template #cell(actions)="{ item }">
        <b-dropdown
          id="download-prayers-options"
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <share-deep-link
            v-if="deepLinkRoute"
            :id="id"
            :route="deepLinkRoute"
          />

          <b-dropdown-item
            v-if="viewRoute"
            :to="{ name: viewRoute, params:{ id: item.id } }"
          >
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="editRoute"
            :to="{ name: editRoute, params:{ id: item.id } }"
          >
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="deleteEndPoint"
            @click="deleteRecord(deleteEndPoint,item.id)"
          >
            <feather-icon
              icon="TrashIcon"
              class="text-danger"
            />
            <span class="align-middle ml-50 text-danger">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import deleteRecordComposition from '@/common/compositions/common/deleteRecord'

export default {
  name: 'CommonTable',
  props: {
    fetchData: { type: Function, default: () => [] },
    pagination: { type: Object, default: () => {} },
    tableColumns: { type: Array, default: () => {} },
    deleteEndPoint: { type: String, default: '' },
    viewRoute: { type: String, default: '' },
    editRoute: { type: String, default: '' },
    deepLinkRoute: { type: String, default: '' },
  },
  setup() {
    const {
      deleteRecord,
      table,
    } = deleteRecordComposition()

    return {
      deleteRecord,
      table,
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
