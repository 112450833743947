import {
  ref,
} from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import store from '@/store'
import handleAlerts from './handleAlerts'

export default function () {
  const {

    successfulDeletionAlert,
    confirmOperation,
  } = handleAlerts()

  const table = ref(null)

  const deleteEntityRequest = (deleteEndPoint, id) => axiosInstances.http.delete(`${deleteEndPoint}${id}`, {
    headers: {
      entityId: store.getters['mainEntity/getEntityId'],
    },
  }).then(() => {
    successfulDeletionAlert()
    table.value.refresh()
  })

  const deleteRecord = (deleteEndPoint, id, message = null) => confirmOperation(message).then(() => deleteEntityRequest(deleteEndPoint, id))
  return {
    deleteRecord,
    table,
  }
}
